<template>
  <div>
    <div class="list-filters mb-1">
      <div class="d-flex justify-content-between">
        <h4>Filtros</h4>
        <div v-if="transactions.length">
          <b>Total de Saldos: </b> <b-badge variant="success" class="mr-1"> {{transactions.length}} </b-badge>
          <b-button
            type="submit"
            size="sm"
            class="btn fives-club-excel-btn mr-1"
            @click="downloadExcelTransactions"
            v-if="requestTransactions && transactions.length && !isDowloadingExcel"
          ><feather-icon icon="FileTextIcon" /> Excel 
          </b-button>
          <b-spinner label="Loading..." v-if="isDowloadingExcel"/>
        </div>
      </div>
  
      <validation-observer v-slot="{ invalid }" ref="searchDetailTransactions">
        <b-form @submit.prevent="getTransactions">
          <b-row class="">
            <!-- Tipo de Busqueda -->
            <b-col md="3" >
              <validation-provider name="tipo Busqueda" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Tipo de Busqueda">
                  <b-form-select
                    class="form-control"
                    v-model="dateType"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="resetDateType"
                  >
                    <option :value="null" disabled> Seleccione una opción</option>
                    <option v-for="dates in dateOptions" :key="dates.value" :value="dates.value">{{dates.text}}</option>
                  </b-form-select>
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Rango de fechas -->
            <b-col md="3" v-if="dateType && dateType != 'benefit'  && dateType != 'contract'">
              <validation-provider name="Fecha inicio" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Fecha inicio">
                  <flat-pickr
                    class="form-control"
                    v-model="dateInit"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3" v-if="dateType && dateType != 'benefit'  && dateType != 'contract'">
              <validation-provider name="Fecha fin" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Fecha fin">
                  <flat-pickr
                    class="form-control"
                    v-model="dateEnd"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Beneficio -->
            <b-col md="3" v-if="dateType && dateType == 'benefit'">
              <validation-provider name="Beneficio" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Beneficio">
                  <b-form-select
                    class="form-control"
                    type="text"
                    v-model="benefit"
                    :state="errors[0] ? false : valid ? true : null"
                  >
                    <option :value="null" disabled>Seleccione un beneficio</option>
                    <option v-for="bnf in benefits" :key="bnf.id" :value="bnf.id">
                      {{bnf.code}}
                    </option>
                  </b-form-select>
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Contrato -->
            <b-col md="3" v-if="dateType && dateType == 'contract'">
              <validation-provider name="Contrato" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Contrato">
                  <b-form-input
                    class="form-control"
                    type="text"
                    v-model="contract"
                    placeholder="Escriba un código de contrato"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Año -->
            <b-col md="3" v-if="dateType && dateType == 'benefit' || dateType == 'contract'">
              <validation-provider name="Año" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Año">
                  <b-form-select
                    class="form-control"
                    type="date"
                    :options="yearOptions"
                    v-model="year"
                    :state="errors[0] ? false : valid ? true : null"
                  >
                    <option :value="null" disabled>Seleccione un año</option>
                  </b-form-select>
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Botones -->
            <b-col>
              <b-form-group label=".">
                <div>
                  <b-button class="mr-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" @click="resetFilter">
                    Reset
                  </b-button>
                  <b-button class="mr-1" type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="invalid">
                    Buscar
                  </b-button>
                </div>
            </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

    <b-row class="mb-1" v-if="transactions.length > 0">
      <b-col md="2">
        <label>Numero de registros</label>
        <v-select
          v-model="porPagina"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector"
          @input="changePerPage"
        />
      </b-col>
      <b-col md="4">
        <label>Buscar en tabla</label>
          <div class="d-flex">
            <b-form-input
              class="mr-1"
              placeholder="Buscar..."
              type="search"
              v-model="filter.queryTransactions"
            />
          </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {  mapState, mapActions, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage } from '@/helpers/helpers'
import * as moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  directives: {
    Ripple,
  },
  mixins: [utils],
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    benefits: {
      type: Array,
      required: false
    }
  },
  components: {
    vSelect,
    flatPickr
  },

  data() {
    return {
      porPagina: this.perPage,
      isDowloadingExcel: false,
      dateInit: null,
      dateEnd: null,
      dateType: null,
      benefit: null,
      year: null,
      dateOptions: [
        {value: 'benefit', text: 'Beneficio'},
        {value: 'contract', text: 'Contrato'},
        {value: 'canceldate', text: 'Fecha de cancelación'},
        {value: 'createdate', text: 'Fecha de creación'},
        {value: 'importdate', text: 'Fecha de importación'},
      ]
    };
  },
  computed: {
    ...mapState('fivesClubReports', ['filter','transactions','requestTransactions']),
    yearOptions(){
      const min = new Date().getFullYear() - 1
      const max = min + 4
      const years = []

      for (var i = min; i <= max; i++) {
        years.push(i)
      }
      return years
    }
  },
  methods: {
    ...mapActions('fivesClubContracts', ['fetchExcelTransactions']),
    ...mapMutations('fivesClubReports', ['setTransactions','setRequestTransactions']),
    changePerPage(perPage) {
      this.$emit('change-per-page', perPage);
      this.porPagina = perPage
    },
     async downloadExcelTransactions(){
      this.isDowloadingExcel = true
      const payload = {
        ...this.requestTransactions,
        DownExcel: true
      }
      await this.fetchExcelTransactions(payload)    
      this.isDowloadingExcel = false
    },
    getTransactions() {
      if (this.dateInit && this.dateEnd) {
        const isValidDates = moment(this.dateEnd).isSameOrAfter(this.dateInit, 'day')
        if(!isValidDates) return showAlertMessage('Fechas inválidas', 'BellIcon', '¡Fecha fin no debe ser menor a fecha de inicio', 'danger', 3000, 'bottom-right')
      }

      const payload = {
        dateType: this.dateType,
        dateIn: this.dateInit,
        dateEnd: this.dateEnd,
        year: this.year,
        contract: this.contract,
        benefit: this.benefit,
      }
      this.$emit('get-transactions', payload)
    },
    resetFilter(){
      this.$refs.searchDetailTransactions.reset()
      this.dateType = null
      this.resetDateType()
      this.setTransactions([])
      this.setRequestTransactions(null)
    },
    resetDateType(){
      this.dateInit = null
      this.dateEnd = null
      this.benefit = null
      this.year = null
      this.contract = null
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
.filters {
  padding-bottom: 2rem;
}
.paddler {
  padding-left: 1rem;
}

.filters {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
}
</style>